// @flow
import types from './customerConstants';

const INIT_STATE = {
    customerList: [],
    customer: {},
    loading: false,
    success: null,
    error: null
};

const Customer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //List
        case types.GET_CUSTOMERS:
            return {
                ...state,
                loading: true
            };
        case types.GET_CUSTOMERS_SUCCESS:

            return {
                ...state,
                customerList: action.payload.data.items,
                hasNextPage: action.payload.data.hasNextPage,
                loading: false,
                error: null
            };
        case types.GET_CUSTOMERS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List

        //Get Customer
        case types.GET_CUSTOMER:
            return {
                ...state,
                loading: true,
                success: null,
                error: null
            };
        case types.GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_CUSTOMER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Customer

        //Post Customer
        case types.POST_CUSTOMER:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload.data,
                loading: false,
                success: 'CUSTOMER.CREATE.SUCCESS',
                error: null
            };
        case types.POST_CUSTOMER_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Post Customer

        //Patch Customer
        case types.PATCH_CUSTOMER:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'CUSTOMER.UPDATE.SUCCESS',
                error: null
            };
        case types.PATCH_CUSTOMER_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Patch Customer

        default:
            return state;
    }
};

export default Customer;
