/* CHECKOUT */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'CHECKOUT/CLEAR_STATE',

    GET_FORM: 'CHECKOUT/GET_FORM',
    GET_FORM_SUCCESS: 'CHECKOUT/GET_FORM_SUCCESS',
    GET_FORM_FAILED: 'CHECKOUT/GET_FORM_FAILED',

    GET_CART: 'CHECKOUT/GET_CART',
    GET_CART_SUCCESS: 'CHECKOUT/GET_CART_SUCCESS',
    GET_CART_FAILED: 'CHECKOUT/GET_CART_FAILED',

    PUT_CART: 'CHECKOUT/PUT_CART',
    PUT_CART_SUCCESS: 'CHECKOUT/PUT_CART_SUCCESS',
    PUT_CART_FAILED: 'CHECKOUT/PUT_CART_FAILED',

    POST_CART: 'CHECKOUT/POST_CART',
    POST_CART_SUCCESS: 'CHECKOUT/POST_CART_SUCCESS',
    POST_CART_FAILED: 'CHECKOUT/POST_CART_FAILED',

    GET_CART_SUMMARY: 'CHECKOUT/GET_CART_SUMMARY',
    GET_CART_SUMMARY_SUCCESS: 'CHECKOUT/GET_CART_SUMMARY_SUCCESS',
    GET_CART_SUMMARY_FAILED: 'CHECKOUT/GET_CART_SUMMARY_FAILED',

    DELETE_CART_ITEM:'CHECKOUT/DELETE_CART_ITEM',
    DELETE_CART_ITEM_SUCCESS:'CHECKOUT/DELETE_CART_ITEM_SUCCESS',
    DELETE_CART_ITEM_FAILED:'CHECKOUT/DELETE_CART_ITEM_FAILED',

    DELETE_CART:'CHECKOUT/DELETE_CART',
    DELETE_CART_SUCCESS:'CHECKOUT/DELETE_CART_SUCCESS',
    DELETE_CART_FAILED:'CHECKOUT/DELETE_CART_FAILED',

    POST_ORDER:'CHECKOUT/POST_ORDER',
    POST_ORDER_SUCCESS:'CHECKOUT/POST_ORDER_SUCCESS',
    POST_ORDER_FAILED:'CHECKOUT/POST_ORDER_FAILED',


    GET_CHECKOUT_PAY: 'CHECKOUT/GET_CHECKOUT_PAY',
    GET_CHECKOUT_PAY_SUCCESS: 'CHECKOUT/GET_CHECKOUT_PAY_SUCCESS',
    GET_CHECKOUT_PAY_FAILED: 'CHECKOUT/GET_CHECKOUT_PAY_FAILED',

    POST_CHECKOUT_PAY: 'CHECKOUT/POST_CHECKOUT_PAY',
    POST_CHECKOUT_PAY_SUCCESS: 'CHECKOUT/POST_CHECKOUT_PAY_SUCCESS',
    POST_CHECKOUT_PAY_FAILED: 'CHECKOUT/POST_CHECKOUT_PAY_FAILED',

    GET_PAYMENT_METHODS: 'CHECKOUT/GET_PAYMENT_METHODS',
    GET_PAYMENT_METHODS_SUCCESS: 'CHECKOUT/GET_PAYMENT_METHODS_SUCCESS',
    GET_PAYMENT_METHODS_FAILED: 'CHECKOUT/GET_PAYMENT_METHODS_FAILED',

    GET_THANKS_PAGE: 'CHECKOUT/GET_THANKS_PAGE',
    GET_THANKS_PAGE_SUCCESS: 'CHECKOUT/GET_THANKS_PAGE_SUCCESS',
    GET_THANKS_PAGE_FAILED: 'CHECKOUT/GET_THANKS_PAGE_FAILED'

});

export default actionTypes;