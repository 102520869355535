import React, { useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import moment from 'moment';

const CalendarComponent: React.FC = () => {
  // Calculate date-related variables similar to the original script
  const todayDate = moment().startOf('day');
  const YM = todayDate.format('YYYY-MM');
  const YESTERDAY = todayDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
  const TODAY = todayDate.format('YYYY-MM-DD');
  const TOMORROW = todayDate.clone().add(1, 'day').format('YYYY-MM-DD');

  // Event configuration similar to the original script
  const events = [
    {
      title: 'Measurement - Ali Kocak',
      start: `${TODAY}T13:30:00`,
      end: `${TODAY}`,
      className: "fc-event-solid-info fc-event-light",
    },
    {
      title: 'All Day Event',
      start: `${YM}-01`,
      description: 'Toto lorem ipsum dolor sit incid idunt ut',
      className: "fc-event-danger fc-event-solid-warning"
    },
    {
      title: 'Reporting',
      start: `${YM}-14T13:30:00`,
      description: 'Lorem ipsum dolor incid idunt ut labore',
      end: `${YM}-14`,
      className: "fc-event-success"
    },
    // ... (include other events from the original script)
    {
      title: 'Click for Google',
      url: 'http://google.com/',
      start: `${YM}-28`,
      className: "fc-event-solid-info fc-event-light",
      description: 'Lorem ipsum dolor sit amet, labore'
    }
  ];

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
      }}
      height={800}
      contentHeight={780}
      aspectRatio={3}
      nowIndicator={true}
      now={`${TODAY}T09:25:00`}
      editable={true}
      dayMaxEvents={true}
      navLinks={true}
      events={events}
      eventContent={(eventInfo) => {
        const description = eventInfo.event.extendedProps.description;
        return (
          <div>
            <div>{eventInfo.event.title}</div>
            {description && <div className="fc-description">{description}</div>}
          </div>
        );
      }}
    />
  );
};

export default CalendarComponent;