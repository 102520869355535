import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Loader, enumPolicies, enumQuoteState, KTIcon, Pagination } from '../../../_metronic/helpers'
import Moment from 'react-moment'
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { PRIMARY_COLOR } from '../../../_metronic/helpers/colorConstant';
import Swal from 'sweetalert2'
import {toast} from 'react-toastify';

import "flatpickr/dist/themes/light.css";

import { clearState, getQuotes, postQuoteAccept, postQuoteInvoiced, postQuoteReject } from './quoteActions';

const Quotes = (props) => {
    MenuComponent.reinitialization();

    //inputs from url
    const intl = useIntl();
    const navigate = useNavigate();
    const [inLoading, setInloading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [keyword, setKeyword] = useState('');
    const searchRef = useRef(null);
    const [page, setPage] = useState(1);
    const [searchTimeout, setSearchTimeout] = useState(0);

    //inputs from redux
    const {
        quotes,
        hasNextPage,
        totalPriceIncVat,
        quoteCounts,
        success,
        loading
    } = props;

    //actions
    const {
        clearState,
        getQuotes,
        postQuoteAccept,
        postQuoteInvoiced,
        postQuoteReject
    } = props;
    
    const tabToQuoteStateMap = {
        0: 0,
        1: enumQuoteState.Invoiced,
        2: enumQuoteState.Accepted,
        3: enumQuoteState.Rejected,
        4: enumQuoteState.Open,
        5: enumQuoteState.NotSent
    };

    useEffect(() => {
        getQuotes({ state: 0, keyword: '', page: page })

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({id: success}));
            getQuotes({ state: 0, keyword: '', page: page })
        }
    }, [success])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization();
        }, 500);
    }, [quotes])

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'QUOTES.BREADCRUMB.TITLE' }),
            path: '/order-management/quotes',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const onKeywordChange = (value) => {
        setKeyword(value)

        if (searchTimeout > 0) {
            clearTimeout(searchTimeout);
        }

        var x = setTimeout(() => {
            getQuotes({ keyword: value, state: tabToQuoteStateMap[selectedTab], page: 1 });
            setPage(1)
        }, 500);

        setSearchTimeout(x)
    }

    const onTabClick = (tab) => {
        setPage(1)
        setSelectedTab(tab)
        getQuotes({
            keyword: keyword,
            state: tabToQuoteStateMap[tab],
            page: 1
        })
    }

    const onInvoicedActionClicked = (id) => {
        Swal.fire({
            title: "",
            text: intl.formatMessage({ id: 'QUOTE.INFO.INVOICED_SENTENCE' }),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
        }).then((result) => {
            if (result.isConfirmed) {
                postQuoteInvoiced(id, {})
            }
        });
    }

    const onAcceptActionClicked = (id) => {
        Swal.fire({
            title: "",
            text: intl.formatMessage({ id: 'QUOTE.INFO.ACCEPT_SENTENCE' }),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
        }).then((result) => {
            if (result.isConfirmed) {
                postQuoteAccept(id, {})
            }
        });
    }

    const onRejectActionClicked = (id) => {
        Swal.fire({
            title: "",
            text: intl.formatMessage({ id: 'QUOTE.INFO.REJECT_SENTENCE' }),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
        }).then((result) => {
            if (result.isConfirmed) {
                postQuoteReject(id, {})
            }
        });
    }

    const getColors = (status, name) => {
        switch (status) {
            case enumQuoteState.Invoiced:
                return <span className="badge badge-primary">{name}</span>
            case enumQuoteState.Accepted:
                return <span className="badge badge-light-success">{name}</span>
            case enumQuoteState.Rejected:
                return <span className="badge badge-light-danger">{name}</span>
            case enumQuoteState.Open:
                return <span className="badge badge-light-warning">{name}</span>
            case enumQuoteState.NotSent:
                return <span className="badge badge-light-dark">{name}</span>
            default:
                return <span className="badge badge-light-primary">{name}</span>
        }
    }

    const onPreviousClick = () => {
        var x = page - 1 <= 1 ? 1 : page - 1;
        getQuotes({
            keyword: keyword,
            state: tabToQuoteStateMap[selectedTab],
            page: x
        })
        setPage(x)
    }

    const onNextClick = () => {
        getQuotes({
            keyword: keyword,
            state: tabToQuoteStateMap[selectedTab],
            page: page + 1
        })
        setPage(page + 1)
    }

    const onReverse = (id) => {

    }

    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'QUOTES.BREADCRUMB.DESCRIPTION' })}</PageTitle>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">

                    {/* Summary Cards Row */}
                    <div className="row g-5 g-xl-8 mb-5">
                        {/* Total Amount Card */}
                        <div className="col-xl-4" style={{ maxWidth: "400px" }}>
                            <div className="card card-xl-stretch mb-xl-8">
                                <div className="card-body d-flex align-items-center pt-3 pb-0">
                                    <div className="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                        <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'QUOTE.TOTAL_INCLUDING_VAT' })}</span>
                                        <span className="fw-bolder fs-2 mb-2">€{totalPriceIncVat}</span>
                                    </div>
                                    <div className="symbol symbol-50px me-2">
                                            <i className={`ki-duotone ki-euro fs-3x`}>
                                                <span className="path1"></span>
                                                <span className="path2"></span>
                                                <span className="path3"></span>
                                            </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Invoiced Quotes Card */}
                        <div className="col-xl-4" style={{ maxWidth: "160px" }}>
                            <div className="card card-xl-stretch mb-xl-8">
                                <div className="card-body d-flex align-items-center pt-3 pb-0">
                                    <div className="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                        <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'QUOTE.FIELD.COUNT' })}</span>
                                        <div className="d-flex align-items-center">
                                            <span className="fw-bolder fs-2 mb-2 me-2">{quoteCounts[enumQuoteState.Invoiced]}</span>
                                            {getColors(enumQuoteState.Invoiced, intl.formatMessage({ id: 'QUOTES.TAB.INVOICED' }))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Accepted Quotes Card */}
                        <div className="col-xl-4" style={{ maxWidth: "160px" }}>
                            <div className="card card-xl-stretch mb-xl-8">
                                <div className="card-body d-flex align-items-center pt-3 pb-0">
                                    <div className="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                        <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'QUOTE.FIELD.COUNT' })}</span>
                                        <div className="d-flex align-items-center">
                                            <span className="fw-bolder fs-2 mb-2 me-2">{quoteCounts[enumQuoteState.Accepted]}</span>
                                            {getColors(enumQuoteState.Accepted, intl.formatMessage({ id: 'QUOTES.TAB.ACCEPTED' }))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rejected Quotes Card */}
                        <div className="col-xl-4" style={{ maxWidth: "160px" }}>
                            <div className="card card-xl-stretch mb-xl-8">
                                <div className="card-body d-flex align-items-center pt-3 pb-0">
                                    <div className="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                        <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'QUOTE.FIELD.COUNT' })}</span>
                                        <div className="d-flex align-items-center">
                                            <span className="fw-bolder fs-2 mb-2 me-2">{quoteCounts[enumQuoteState.Rejected]}</span>
                                            {getColors(enumQuoteState.Rejected, intl.formatMessage({ id: 'QUOTES.TAB.REJECTED' }))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Open Quotes Card */}
                        <div className="col-xl-4" style={{ maxWidth: "160px" }}>
                            <div className="card card-xl-stretch mb-xl-8">
                                <div className="card-body d-flex align-items-center pt-3 pb-0">
                                    <div className="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                        <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'QUOTE.FIELD.COUNT' })}</span>
                                        <div className="d-flex align-items-center">
                                            <span className="fw-bolder fs-2 mb-2 me-2">{quoteCounts[enumQuoteState.Open]}</span>
                                            {getColors(enumQuoteState.Open, intl.formatMessage({ id: 'QUOTES.TAB.OPEN' }))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Unsent Quotes Card */}
                        <div className="col-xl-4" style={{ maxWidth: "160px" }}>
                            <div className="card card-xl-stretch mb-xl-8">
                                <div className="card-body d-flex align-items-center pt-3 pb-0">
                                    <div className="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                                        <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'QUOTE.FIELD.COUNT' })}</span>
                                        <div className="d-flex align-items-center">
                                            <span className="fw-bolder fs-2 mb-2 me-2">{quoteCounts[enumQuoteState.NotSent]}</span>
                                            {getColors(enumQuoteState.NotSent, intl.formatMessage({ id: 'QUOTES.TAB.NOT_SENT' }))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="card card-flush  ">
                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                            <div className="card-title">
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" data-kt-ecommerce-order-filter="search" className="form-control form-control-solid w-250px ps-12" ref={searchRef} placeholder={intl.formatMessage({ id: 'QUOTES.SEARCH' })} onChange={(e) => onKeywordChange(e.target.value)} />
                                </div>
                            </div>
                            <div className="card-toolbar">
                                <PolicyChecker policies={[enumPolicies.QuoteEdit, enumPolicies.QuoteWrite]}>
                                    <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                        <Link to={`/order-management/quotes/0?tab=2`} type="button" className="btn btn-success"><i className="ki-duotone ki-plus fs-2"></i>{intl.formatMessage({ id: 'QUOTES.CREATE' })}</Link>
                                    </div>
                                </PolicyChecker>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="">
                                <ul className="nav nav-tabs nav-line-tabs  fs-5 nav-line-tabs-2x">
                                    <li className="nav-item active ">
                                        <a className="nav-link text-active-primary active" data-bs-toggle="tab" href="#kt_ecommerce_sales_quotes" onClick={() => onTabClick(0)}>{intl.formatMessage({ id: "QUOTES.TAB.ALL" })}</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link text-active-primary" data-bs-toggle="tab" href="#kt_ecommerce_sales_quotes" onClick={() => onTabClick(1)}>{intl.formatMessage({ id: "QUOTES.TAB.INVOICED" })}</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link text-active-primary" data-bs-toggle="tab" href="#kt_ecommerce_sales_quotes" onClick={() => onTabClick(2)}>{intl.formatMessage({ id: "QUOTES.TAB.ACCEPTED" })}</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link text-active-primary" data-bs-toggle="tab" href="#kt_ecommerce_sales_quotes" onClick={() => onTabClick(3)}>{intl.formatMessage({ id: "QUOTES.TAB.REJECTED" })}</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link text-active-primary" data-bs-toggle="tab" href="#kt_ecommerce_sales_quotes" onClick={() => onTabClick(4)}>{intl.formatMessage({ id: "QUOTES.TAB.OPEN" })}</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link text-active-primary" data-bs-toggle="tab" href="#kt_ecommerce_sales_quotes" onClick={() => onTabClick(5)}>{intl.formatMessage({ id: "QUOTES.TAB.NOT_SENT" })}</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="kt_ecommerce_sales_quotes" role="tab-panel">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0" style={{ minWidth: "600px" }}>
                                                <thead>
                                                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                        <th>{intl.formatMessage({ id: "QUOTES.FIELD.NUMBER" })}</th>
                                                        <th>{intl.formatMessage({ id: "QUOTES.FIELD.NAME" })}</th>
                                                        <th>{intl.formatMessage({ id: "QUOTES.FIELD.DATE" })}</th>
                                                        <th>{intl.formatMessage({ id: "QUOTES.FIELD.AMOUNT" })}</th>
                                                        <th>{intl.formatMessage({ id: "QUOTES.FIELD.STATUS" })}</th>
                                                        <PolicyChecker policies={[enumPolicies.QuoteEdit, enumPolicies.QuoteWrite]}>
                                                            <th>{intl.formatMessage({ id: "QUOTES.ACTIONS" })}</th>
                                                        </PolicyChecker>
                                                    </tr>
                                                </thead>
                                                <tbody className="fw-semibold text-gray-600">
                                                    {quotes && quotes.map((item) => {
                                                        return (
                                                            <tr key={`quotes_${item.id}`}>
                                                                <td>
                                                                    <Link to={`/order-management/quotes/${item.id}?tab=1`} >{item.quoteNr ? item.quoteNr : '-'}</Link>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.user !== undefined ? 
                                                                        <div className="d-flex flex-column">
                                                                            <a className="text-gray-800 text-hover-primary mb-1">{item.user.contact && item.user.contact.company}</a>
                                                                            <span style={{wordBreak: 'break-all'}}>{item.user.name} {item.user.surname}</span>
                                                                        </div> : item.customer ?
                                                                        <div className="d-flex flex-column">
                                                                            <span style={{wordBreak: 'break-all'}}>{item.customer.name} {item.customer.surname}</span>
                                                                        </div> : "-"
                                                                    }
                                                                </td>
                                                                <td><Moment format='ddd DD MMM'>{item.createdOn}</Moment></td>
                                                                <td>€ {item.totalPriceIncVat}</td>
                                                                <td>{getColors(item.quoteState, item.quoteStateName)}</td>
                                                                <PolicyChecker policies={[enumPolicies.QuoteEdit, enumPolicies.QuoteWrite]}>
                                                                    <td>
                                                                        <a className='btn btn-light btn-active-light-primary btn-sm ' data-kt-menu-trigger="{default: 'click'}" data-kt-menu-placement='bottom-end'> {intl.formatMessage({ id: 'QUOTES.ACTIONS' })}
                                                                            <KTIcon iconName='down' className='fs-5 m-0' />
                                                                        </a>
                                                                        <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4' data-kt-menu='true'>
                                                                            <div className='menu-item px-3'>
                                                                                <Link to={`/order-management/quotes/${item.id}?tab=2`} className='menu-link px-3'> {intl.formatMessage({ id: 'QUOTES.ACTIONS.EDIT' })} </Link>
                                                                            </div>
                                                                            <div className='menu-item px-3'>
                                                                                <a className='menu-link px-3' onClick={() => onInvoicedActionClicked(item.id)}> {intl.formatMessage({ id: 'QUOTES.ACTIONS.INVOICED' })} </a>
                                                                            </div>
                                                                            <div className='menu-item px-3'>
                                                                                <a className='menu-link px-3' onClick={() => onAcceptActionClicked(item.id)}> {intl.formatMessage({ id: 'QUOTES.ACTIONS.ACCEPT' })} </a>
                                                                            </div>
                                                                            <div className='menu-item px-3'>
                                                                                <a className='menu-link px-3' onClick={() => onRejectActionClicked(item.id)}> {intl.formatMessage({ id: 'QUOTES.ACTIONS.REJECT' })} </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </PolicyChecker>

                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <Pagination page={page} hasNextPage={hasNextPage} onPreviousClick={() => onPreviousClick()} onNextClick={() => onNextClick()}></Pagination>                            
                        </div>
                    </div>
                </div>
            </div>

            {(loading || inLoading) && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        quotes: state.Quote.quotes,
        hasNextPage: state.Quote.hasNextPage,
        totalPriceIncVat : state.Quote.totalPriceIncVat,
        quoteCounts : state.Quote.quoteCounts,
        loading: state.Quote.loading,
        success: state.Quote.success
    };
};

const mapActionsToProps = {
    clearState, getQuotes, postQuoteAccept, postQuoteInvoiced, postQuoteReject
}

export default connect(mapStateToProps, mapActionsToProps)(Quotes)