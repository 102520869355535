// @flow
import types from './quoteConstants';
import { getAsync, postAsync, deleteAsync, patchAsync, putAsync, uploadAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getQuotes = (filter) => async dispatch => {

    dispatch({
        type: types.GET_QUOTES
    })

    var path = `/quotes?`;

    if (filter) {
        path += "keyword=" + filter.keyword + "&";
        path += "state=" + filter.state + "&";
        path += "page=" + filter.page;
    }

    const response = await getAsync(path);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_QUOTES_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_QUOTES_FAILED,
            payload: response,
        })
    }
}

export const getQuote = (id) => async dispatch => {

    dispatch({
        type: types.GET_QUOTE
    })

    const response = await getAsync(`/quotes/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_QUOTE_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_QUOTE_FAILED,
            payload: response,
        })
    }
}

export const patchQuote = (id, request) => async dispatch => {

    dispatch({
        type: types.PATCH_QUOTE
    })

    const response = await patchAsync(`/quotes/${id}`, request);

    if (response.isSuccess) {
        dispatch(getQuote(id))
        dispatch({
            type: types.PATCH_QUOTE_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_QUOTE_FAILED,
            payload: response,
        })
    }

    return response;
}

export const patchQuoteItemsFromCart = (id) => async dispatch => {

    dispatch({
        type: types.PATCH_QUOTE_FROM_CART
    })

    const response = await patchAsync(`/quotes/${id}/items/cart`);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_QUOTE_FROM_CART_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PATCH_QUOTE_FROM_CART_FAILED,
            payload: response,
        })
    }

    return response;
}

export const postQuote = (request) => async dispatch => {

    dispatch({
        type: types.POST_QUOTE
    })

    const response = await postAsync(`/quotes`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_QUOTE_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.POST_QUOTE_FAILED,
            payload: response,
        })
    }

    return response;
}

export const postQuoteByCart = (request) => async dispatch => {

    dispatch({
        type: types.POST_QUOTE_CART
    })

    const response = await postAsync(`/quotes/cart`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_QUOTE_CART_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.POST_QUOTE_CART_FAILED,
            payload: response,
        })
    }

    return response;
}

export const putItems = (id, request) => async dispatch => {

    dispatch({
        type: types.PUT_QUOTE_ITEMS
    })

    const response = await putAsync(`/quotes/${id}/items`, request);

    if (response.isSuccess) {
        dispatch(getQuote(id))
        dispatch({
            type: types.PUT_QUOTE_ITEMS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.PUT_QUOTE_ITEMS_FAILED,
            payload: response,
        })
    }
}

export const getHistory = (id) => async dispatch => {
    dispatch({
        type: types.GET_HISTORY
    })

    const response = await getAsync(`/quotes/${id}/history`);

    dispatch({
        type: types.GET_HISTORY_SUCCESS,
        payload: response,
    })
}

export const postQuoteEmail = (id, request) => async dispatch => {

    dispatch({
        type: types.POST_QUOTE_EMAIL
    })

    const response = await postAsync(`/quotes/${id}/email`, request);

    if (response.isSuccess) {
        dispatch(getQuote(id))
        dispatch({
            type: types.POST_QUOTE_EMAIL_SUCCESS
        })
    } else {
        dispatch({
            type: types.POST_QUOTE_EMAIL_FAILED,
        })
    }
}

export const postQuoteAccept = (id, request) => async dispatch => {

    dispatch({
        type: types.POST_QUOTE_ACCEPT
    })

    const response = await postAsync(`/quotes/${id}/accept`, request);

    if (response.isSuccess) {
        dispatch(getQuote(id))
        dispatch({
            type: types.POST_QUOTE_ACCEPT_SUCCESS
        })
    } else {
        dispatch({
            type: types.POST_QUOTE_ACCEPT_FAILED,
        })
    }
}

export const postQuoteReject = (id, request) => async dispatch => {

    dispatch({
        type: types.POST_QUOTE_ACCEPT
    })

    const response = await postAsync(`/quotes/${id}/reject`, request);

    if (response.isSuccess) {
        dispatch(getQuote(id))
        dispatch({
            type: types.POST_QUOTE_REJECT_SUCCESS
        })
    } else {
        dispatch({
            type: types.POST_QUOTE_REJECT_FAILED,
        })
    }
}

export const postQuoteInvoiced = (id, request) => async dispatch => {

    dispatch({
        type: types.POST_QUOTE_INVOICED
    })

    const response = await postAsync(`/quotes/${id}/invoiced`, request);

    if (response.isSuccess) {
        dispatch(getQuote(id))
        dispatch({
            type: types.POST_QUOTE_INVOICED_SUCCESS
        })
    } else {
        dispatch({
            type: types.POST_QUOTE_INVOICED_FAILED,
        })
    }
}

export const postQuoteConvertToOrder = (id, request) => async dispatch => {

    dispatch({
        type: types.POST_QUOTE_EMAIL
    })

    const response = await postAsync(`/quotes/${id}/convert-to-order`, request);

    if (response.isSuccess) {
        dispatch(getQuote(id))
        dispatch({
            type: types.POST_QUOTE_EMAIL_SUCCESS
        })
    } else {
        dispatch({
            type: types.POST_QUOTE_EMAIL_FAILED,
        })
    }
}

export const postQuoteAddToCart = (id, request) => async dispatch => {

    dispatch({
        type: types.POST_QUOTE_ADD_TO_CART
    })

    const response = await postAsync(`/quotes/${id}/add-to-cart`, request);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_QUOTE_ADD_TO_CART_SUCCESS
        })
    } else {
        dispatch({
            type: types.POST_QUOTE_ADD_TO_CART_FAILED,
        })
    }
}
