import { FC, useState, useEffect } from 'react'
import { useLang } from './Metronici18n'
import { IntlProvider } from 'react-intl'
import { getAsync } from '../helpers/httpClient'
import { Loader } from '../helpers'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'

import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
import nlMessages from './messages/nl.json'
import trMessages from './messages/tr.json'
import { WithChildren } from '../helpers'

const allMessages = {
  de: deMessages,
  en: enMessages,
  nl: nlMessages,
  tr: trMessages
}


const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang()
  // const [resources, setResources] = useState({});
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {

  //   const getResources = async () => {
  //     const response = await getAsync(`/resources/${locale}`)
  //     if (response && response.data) {

  //       const result = response.data.reduce((acc, item) => {
  //         acc[item.name] = item.value;
  //         return acc;
  //       }, {});

  //       setLoading(false)
  //       setResources(result);
  //     }
  //   }
  //   getResources();
  // }, [])

  // if (loading) {
  //   return (
  //     <Loader />
  //   )
  // }

  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
