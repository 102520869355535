
const OrderComponentPrint = (props) => {

    const { item = null, order = null, content = null, intl } = props;

    const renderDetail = (content) => {
        var detail = content.additionalAttributesJson != '' && JSON.parse(content.additionalAttributesJson);
        if (detail) {
            return (
                <>
                    {detail.Width ? <p>{intl.formatMessage({ id: 'PRINT.WIDTH' })}: {detail.Width}</p> : null}
                    {detail.Depth ? <p>{intl.formatMessage({ id: 'PRINT.DEPTH' })}: {detail.Depth}</p> : null}
                    {detail.Height ? <p>{intl.formatMessage({ id: 'PRINT.HEIGHT' })}: {detail.Height}</p> : null}
                    {detail.FrameColorName ? <p>{intl.formatMessage({ id: 'PRINT.FRAME_COLOR' })}: {detail.FrameColorName}</p> : null}
                    {detail.OtherFrameColor ? <p>{intl.formatMessage({ id: 'PRINT.OTHER_FRAME_COLOR' })}: {detail.OtherFrameColor}</p> : null}
                    {detail.Length ? <p>{intl.formatMessage({ id: 'PRINT.LENGTH' })}: {detail.Length}</p> : null}
                    {detail.FrontHeight ? <p>{intl.formatMessage({ id: 'PRINT.FRONT_HEIGHT' })}: {detail.FrontHeight}</p> : null}
                    {detail.RearHeight ? <p>{intl.formatMessage({ id: 'PRINT.REAR_HEIGHT' })}: {detail.RearHeight}</p> : null}
                    {detail.OrderTypeName ? <p>{intl.formatMessage({ id: 'PRINT.ORDER_TYPE' })}: {detail.OrderTypeName}</p> : null}
                    {detail.StandTypeName ? <p>{intl.formatMessage({ id: 'PRINT.STAND_TYPE' })}: {detail.StandTypeName}</p> : null}
                    {detail.RoofTypeName ? <p>{intl.formatMessage({ id: 'PRINT.ROOF_TYPE' })}: {detail.RoofTypeName}, {detail.ModelTypeName}</p> : null}
                    {detail.LedTypeName ? <p>{intl.formatMessage({ id: 'PRINT.LED' })}: {detail.LedTypeName}</p> : null}
                    {detail.LedColorName ? <p>{intl.formatMessage({ id: 'PRINT.LED_COLOR' })}: {detail.LedColorName}</p> : null}
                    {detail.NrOfLedPerColumn ? <p>{intl.formatMessage({ id: 'PRINT.NR_OF_LED' })}: {detail.NrOfLedPerColumn}</p> : null}
                    {detail.MaterialColorName ? <p>{intl.formatMessage({ id: 'PRINT.MATERIAL_COLOR' })}: {detail.MaterialColorName}</p> : null}
                    {detail.MaterialTypeName ? <p>{intl.formatMessage({ id: 'PRINT.MATERIAL_TYPE' })}: {detail.MaterialTypeName}</p> : null}
                    {detail.SideName ? <p>{intl.formatMessage({ id: 'PRINT.SIDE' })}: {detail.SideName}</p> : null}
                    {detail.RailSetTypeName ? <p>{intl.formatMessage({ id: 'PRINT.RAIL_SET' })}: {detail.RailSetTypeName}</p> : null}
                    {detail.IsMeenemerAdded ? <p>{intl.formatMessage({ id: 'PRINT.HAS_MEENEMER_ADDED' })}: {detail.IsMeenemerAdded ? "Yes" : "No"}</p> : null}
                    {detail.GlassWidth ? <p>{intl.formatMessage({ id: 'PRINT.GLASS_WIDTH' })}: {detail.GlassWidth}</p> : null}
                    {detail.OtherGlassWidth ? <p>{intl.formatMessage({ id: 'PRINT.OTHER_GLASS_WIDTH' })}: {detail.OtherGlassWidth}</p> : null}
                    {detail.GlassHeight ? <p>{intl.formatMessage({ id: 'PRINT.GLASS_HEIGHT' })}: {detail.GlassHeight}</p> : null}
                    {detail.OtherGlassHeight ? <p>{intl.formatMessage({ id: 'PRINT.OTHER_GLASS_HEIGHT' })}: {detail.OtherGlassHeight}</p> : null}
                    {detail.FreeText ? <p>{intl.formatMessage({ id: 'PRINT.DESCRIPTION' })}: {detail.FreeText}</p> : null}
                </>
            )
        }

        return null;
    }

    return (
        <>
            <title>&nbsp;</title>
            <style>
                {`
             html, #smprint div, #smprint span, #smprint applet, #smprint object, #smprint iframe, #smprint h1, #smprint h2, #smprint h3, #smprint h4, #smprint h5, #smprint h6, #smprint p, #smprint blockquote, #smprint pre, #smprint a, #smprint abbr, #smprint acronym, #smprint address, #smprint big, #smprint cite, #smprint code, #smprint del, #smprint dfn, #smprint em, #smprint img, #smprint ins, #smprint kbd, #smprint q, #smprint s, #smprint samp, #smprint small, #smprint strike, #smprint sub, #smprint sup, #smprint tt, #smprint var, #smprint b, #smprint u, #smprint i, #smprint center, #smprint dl, #smprint dt, #smprint dd, #smprint ol, #smprint ul, #smprint li, #smprint fieldset, #smprint form, #smprint label, #smprint legend, #smprint table, #smprint caption, #smprint tbody, #smprint tfoot, #smprint thead, #smprint tr, #smprint th, #smprint td, #smprint article, #smprint aside, #smprint canvas, #smprint details, #smprint embed, #smprint figure, #smprint figcaption, #smprint footer, #smprint header, #smprint hgroup, #smprint menu, #smprint nav, #smprint output, #smprint ruby, #smprint section, #smprint summary, #smprint time, #smprint mark, #smprint audio, #smprint video {
              margin: 0;
              padding: 0;
              border: 0;
              font-size: 100%;
              font: inherit;
              vertical-align: baseline;
            }
  
            /* HTML5 display-role reset for older browsers */
            article, aside, details, figcaption, figure,
            footer, header, hgroup, menu, nav, section {
                display: block;
            }
  
            body {
                margin: 5;
                padding: 5;
            }
  
            #smprint blockquote, #smprint q {
                quotes: none;
            }
  
            #smprint blockquote:before, #smprint blockquote:after,
            #smprint q:before, #smprint q:after {
                content: '';
                content: none;
            }
  
            b {
              font-weight: bolder !important;
            }

            #smprint table {
                font-family: arial, sans-serif;
                line-height: 30px
            }
  
            #smprint.smprint-new table table { font-family: unset; width: 100%; } 
  
            `}
            </style>
            <div id="smprint" className={'smprint-new'} >
                <div>
                    <table width="720" height="350">
                        <tr>
                            <td colSpan={3} style={{ textAlign: "center", fontSize: "20px" }}><b>{intl.formatMessage({ id: 'PRINT.ORDER_NUMBER' })} : {order.id}</b></td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: "middle", textAlign: "center" }} width={200}>
                                {
                                    content.imageLink ? <img src={content.imageLink} width={200}></img> : null
                                }
                            </td>
                            <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td width="30%"><b>{intl.formatMessage({ id: 'PRINT.CLIENT' })} :</b>  </td>
                                            <td width="70%">{order.user && order.user.contact && order.user.contact.company} </td>
                                        </tr>
                                        <tr>
                                            <td width="30%"> <b>{intl.formatMessage({ id: 'PRINT.REF' })} :</b> </td>
                                            <td width="70%">{order && order.reference} </td>
                                        </tr>
                                        <tr>
                                            <td width="30%"><b>{intl.formatMessage({ id: 'PRINT.PRODUCT' })} :</b> </td>
                                            <td width="70%">{content.productId} - {content.productName}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%"><b>{intl.formatMessage({ id: 'PRINT.VARIANT' })} :</b> </td>
                                            <td width="70%">{content.variantId} - {content.variantName}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%"><b>{intl.formatMessage({ id: 'PRINT.QUANTITY' })} :</b> </td>
                                            <td width="70%">{content.quantity}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%"><b>{intl.formatMessage({ id: 'PRINT.DETAILS' })} :</b> </td>
                                            <td width="70%">
                                                {renderDetail(content)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style={{ verticalAlign: "middle", textAlign: "center" }} >
                                <div style={{ border: "black solid 2px", width: "40px", height: "40px" }}></div>
                            </td>
                        </tr>

                    </table>
                </div>
            </div>
        </>
    );
};
export default OrderComponentPrint;